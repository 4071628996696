import React, {Component} from 'react';
import './App.css';
import {FunctionalOverview} from "./components/FunctionalOverview";
import {Architecture} from "./components/Architecture";
import {Technologies} from "./components/Technologies";

class App extends Component {
    state = {
        currentPage: 0
    }
    handleChangeOverview = (e) => {
        e.preventDefault();
        this.setState({currentPage: 0})
    }
    handleChangeArchitecture = (e) => {
        e.preventDefault();
        this.setState({currentPage: 1})
    }
    handleChangeTechnologies = (e) => {
        e.preventDefault();
        this.setState({currentPage: 2})
    }

    render() {
        let currentPage;
        if (this.state.currentPage === 0) {
            currentPage = <FunctionalOverview/>
        } else if (this.state.currentPage === 1) {
            currentPage = <Architecture/>
        } else if (this.state.currentPage === 2) {
            currentPage = <Technologies/>
        }
        return (
            <div className="text-center dark-container">
                <div className="cover-container d-flex h-100 p-3 mx-auto flex-column">
                    <header className="masthead mb-auto">
                        <div className="inner">
                            <h3 className="masthead-brand">Eternoglyph</h3>
                            <nav className="nav nav-masthead justify-content-center">
                                <a href="/#" className={'nav-link' + (this.state.currentPage === 0 ? ' active' : '')}
                                   onClick={this.handleChangeOverview}>Functional
                                    overview</a>
                                <a href="/#" className={'nav-link' + (this.state.currentPage === 1 ? ' active' : '')}
                                   onClick={this.handleChangeArchitecture}>Architecture</a>
                                <a href="/#" className={'nav-link' + (this.state.currentPage === 2 ? ' active' : '')}
                                   onClick={this.handleChangeTechnologies}>Technologies</a>
                            </nav>
                        </div>
                    </header>

                    <main role="main" className="inner cover">
                        {currentPage}
                    </main>
                    <footer className="mastfoot mt-auto">
                        <div className="inner">
                            <p>Copyright © <a href="https://eternoglyph.com/"> Eternoglyph</a> 2020 - {new Date().getFullYear()}.</p>
                        </div>
                    </footer>
                </div>

            </div>
        );
    }
}

export default App;
