import React from 'react';
import architecture from "../architecture.png";

export const Architecture = () => {
    return (
        <React.Fragment>
            <h5 className="cover-heading">Architecture</h5>
            <img src={architecture} alt='Eternoglyph Architecture' style={{maxWidth: '100%', height: 'auto'}}/>
        </React.Fragment>
    );
}