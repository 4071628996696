import React from 'react';

export const FunctionalOverview = () => {
    return (
        <React.Fragment>
            <h5 className="cover-heading">Functional overview</h5>
            <div className="videoWrapper">
            <iframe src="https://www.youtube-nocookie.com/embed/gkbEpSBMGcY" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen title='Eternoglyph functional presentation'/>
            </div>
        </React.Fragment>
    );
}
