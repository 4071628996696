import React from 'react';

export const Technologies = () => {
    return (
        <React.Fragment>
            <h5 className="cover-heading" id="#technologies">Technologies</h5>
            <ul>
                <li className="lead">Java 8, Spring Boot 2, Spring Security, Spring AOP, Spring Validation, Spring
                    Retry, etc.
                </li>
                <li className="lead">Passay, Lombok, etc.</li>
                <li className="lead">Kubernetes</li>
                <li className="lead">Docker</li>
                <li className="lead">Amazon Web Services (IAM, S3, SES, DynamoDB)</li>
                <li className="lead">Google Cloud (Kubernetes Engine, Cloud DNS, Load Balancer)</li>
                <li className="lead">OAuth2 & JWT</li>
                <li className="lead">ReactJS (MaterialUI, Axios, JWT Decode, React Router, Universal Cookie, etc)</li>
            </ul>
        </React.Fragment>
    );
}